export const ACTUAL_DATA = 'ACTUAL_DATA';
export const USER_DATA = 'USER_DATA';
export const ACTIVE_FORM = 'ACTIVE_FORM';

export const availableForms = {
  BAMBOO_TESTER: 'BAMBOO_TESTER',
  PREPARE_CLUSTER: 'PREPARE_CLUSTER',
  TEST_CLUSTER: 'TEST_CLUSTER',
};

export const allowedLinuxes = [
  { label: 'Amazon Linux 2', value: 'al2' },
  { label: 'Amazon Linux 2023', value: 'al23' },
  { label: 'CentOS 7', value: 'centos7' },
  { label: 'Debian GNU/Linux 10', value: 'debian10' },
  { label: 'Debian GNU/Linux 11', value: 'debian11' },
  { label: 'Oracle Linux 8.4', value: 'ol8' },
  { label: 'RHEL 7', value: 'rhel7' },
  { label: 'RHEL 8.4', value: 'rhel8' },
  { label: 'Rocky Linux 8', value: 'rocky8' },
  { label: 'Rocky Linux 9', value: 'rocky9' },
  { label: 'SUSE Linux Enterprise Server 15', value: 'sles15' },
  { label: 'Ubuntu 20.04', value: 'ubuntu20' },
  { label: 'Ubuntu 22.04', value: 'ubuntu22' },
  { label: 'Run on all linuxes', value: 'all' },
];

export const allowedDBMS = [
  { label: 'MySQL 5.7', value: 'mysql-5.7' },
  { label: 'MySQL 8.0', value: 'mysql-8.0' },
  { label: 'Percona 5.7', value: 'percona-5.7' },
  { label: 'Percona 8.0', value: 'percona-8.0' },
  { label: 'MariaDB 10.4', value: 'mariadb-10.4' },
  { label: 'MariaDB 10.5', value: 'mariadb-10.5' },
  { label: 'MariaDB 10.6', value: 'mariadb-10.6' },
  { label: 'MariaDB 10.11', value: 'mariadb-10.11' },
  { label: 'Run on all versions', value: 'all' },
];

export const allowedRegions = [
  { label: 'use (us-east)', value: 'use' },
  { label: 'usw (us-west)', value: 'usw' },
  { label: 'euw (eu-west)', value: 'euw' },
  { label: 'euc (eu-central)', value: 'euc' },
  { label: 'ap (ap-southeast)', value: 'ap' },
  { label: '3use,3euw (multi-region, per 3 nodes)', value: '3use,3euw' },
  { label: '3use,3euw,3ap (multi-region, per 3 nodes)', value: '3use,3euw,3ap' },
];

export const allowedJavaVersions = [
  { label: '8', value: '8' },
  { label: '11', value: '11' },
  { label: '17', value: '17' },
  { label: '21', value: '21' },
  { label: 'Azul 8', value: 'azul8' },
  { label: 'Azul 11', value: 'azul11' },
];

export const allowedInstallMethods = [
  { label: 'ini', value: 'ini' },
  { label: 'staging', value: 'staging' },
];

export const proposedTopologies = [
  { label: 'STD 3 nodes', value: 'std-3' },
  { label: 'STD 2 nodes + witness', value: 'std-2+1w' },
  { label: 'CMS (CAP) 2 sites x 3 nodes', value: 'cms-2x3' },
  { label: 'CMS (CAP) 2 sites x 2 nodes + witness', value: 'cms-2x2+1w' },
  { label: 'CMM (CAA) 2 sites x 3 nodes', value: 'cmm-2x3' },
  { label: 'CMM (CAA) 2 sites x 2 nodes + witness', value: 'cmm-2x2+1w' },
  { label: 'MSMM (MSAA) 2 sites x 3 nodes', value: 'msmm-2x3' },
];

export const proposedVersions = [{ label: 'latest version found', value: 'latest' }];

export const proposedBranches = [{ label: 'main branch (mostly cnt-x.y.z-merge)', value: 'main' }];

export const proposedInstanceTypes = [
  { label: 'm5.large', value: 'm5.large' },
  { label: 'm5.xlarge', value: 'm5.xlarge' },
];

export const proposedRepeats = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
];

export const proposedNodesCount = [
  { label: '1', value: '1' },
  { label: '3', value: '3' },
  { label: '6', value: '6' },
];

export const bambooLinks = {
  BAMBOO_TESTER: 'https://bamboo.continuent.com:8443/browse/CT-TEST/latest',
  PREPARE_CLUSTER: 'https://bamboo.continuent.com:8443/browse/CT-PRCL/latest',
  TEST_CLUSTER: 'https://bamboo.continuent.com:8443/browse/CT-TCL/latest',
};
