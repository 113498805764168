import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Container, Row, Col, Modal, Form as BForm, Button, ButtonGroup, Toast, ListGroup } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import { map, filter, includes } from 'lodash';

import { USER_DATA, ACTIVE_FORM, availableForms, bambooLinks } from './enums';
import { openLink, saveToLocalStorage, readFromLocalStorage } from './utils';
import { SimpleCheckbox, CheckboxWithTextField, GroupOptions, TestsuitesOptionsInModal } from './FormComponents';
import { formFields, createOptions } from './options';

const App = () => {
  const [activeModal, setActiveModal] = useState();
  const [initialValues, setInitialValues] = useState();
  const [userOptions, setUserOptions] = useState([]);
  const [activeForm, setStateActiveForm] = useState(availableForms.BAMBOO_TESTER);

  useEffect(() => {
    let customInitialValues = readFromLocalStorage();

    if (!customInitialValues) {
      resetInitialValues();
    } else {
      setInitialValues(customInitialValues);
    }

    let customActiveForm = readFromLocalStorage(ACTIVE_FORM);

    if (customActiveForm) {
      setStateActiveForm(customActiveForm);
    }

    setUserOptions(readFromLocalStorage(USER_DATA));
  }, []);

  const resetInitialValues = () => {
    let customInitialValues = {};

    map(formFields, formField => {
      if (formField.initialValue || formField.initialValue === '') {
        customInitialValues[formField.name] = formField.initialValue;
      }
    });

    setInitialValues(customInitialValues);
  };

  const setActiveForm = formName => {
    setStateActiveForm(formName);
    saveToLocalStorage(formName, ACTIVE_FORM);
  };

  const getDirectLink = activeForm => {
    switch (activeForm) {
      case availableForms.BAMBOO_TESTER:
        return <p onClick={() => openLink(bambooLinks.BAMBOO_TESTER)}>Open Bamboo Tester</p>;
      case availableForms.PREPARE_CLUSTER:
        return <p onClick={() => openLink(bambooLinks.PREPARE_CLUSTER)}>Open Prepare Cluster</p>;
      case availableForms.TEST_CLUSTER:
        return <p onClick={() => openLink(bambooLinks.TEST_CLUSTER)}>Open Test Cluster</p>;
      default:
        return null;
    }
  };

  if (!initialValues) {
    return <div>Loading...</div>;
  }

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {({ values, resetForm }) => {
        const resultText = createOptions(values, activeForm);
        saveToLocalStorage(values);

        return (
          <Container fluid>
            <Row>
              <Col md={9}>
                <Form className="form">
                  <Row>
                    <Col className="header-switch">
                      <ButtonGroup>
                        <Button
                          type="button"
                          variant="outline-primary"
                          active={activeForm === availableForms.BAMBOO_TESTER}
                          onClick={() => setActiveForm(availableForms.BAMBOO_TESTER)}
                        >
                          Bamboo Tester
                        </Button>
                        <Button
                          type="button"
                          variant="outline-primary"
                          active={activeForm === availableForms.PREPARE_CLUSTER}
                          onClick={() => setActiveForm(availableForms.PREPARE_CLUSTER)}
                        >
                          Prepare Cluster
                        </Button>
                        <Button
                          type="button"
                          variant="outline-primary"
                          active={activeForm === availableForms.TEST_CLUSTER}
                          onClick={() => setActiveForm(availableForms.TEST_CLUSTER)}
                        >
                          Test Cluster
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="header-link">{getDirectLink(activeForm)}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <BForm.Control as="textarea" rows={3} disabled value={resultText} />
                      <ButtonGroup className="float-right">
                        <Button
                          type="button"
                          variant="outline-primary"
                          onClick={() => {
                            resetInitialValues();
                            resetForm();
                          }}
                        >
                          Reset
                        </Button>
                        <CopyToClipboard text={resultText} onCopy={() => setActiveModal('copied')}>
                          <Button type="button" variant="outline-primary">
                            Copy to clipboard
                          </Button>
                        </CopyToClipboard>
                      </ButtonGroup>
                      <Toast onClose={() => setActiveModal()} show={activeModal === 'copied'} autohide delay={1000}>
                        <Toast.Body>Copied</Toast.Body>
                      </Toast>
                    </Col>
                  </Row>

                  {map(formFields, formField => {
                    if (!includes(formField.forms, activeForm)) {
                      return null;
                    } else if (formField.element === 'h4') {
                      return <h4 key={formField.name}>{formField.text}</h4>;
                    } else if (formField.element === 'SimpleCheckbox') {
                      return (
                        <SimpleCheckbox
                          key={formField.name}
                          name={formField.name}
                          displayConditions={formField.displayConditions}
                          values={values}
                          text={formField.text}
                          initial={formField.initialValue}
                        />
                      );
                    } else if (formField.element === 'CheckboxWithTextField') {
                      return (
                        <CheckboxWithTextField
                          key={formField.name}
                          name={formField.name}
                          values={values}
                          text={formField.text}
                          initial={formField.initialValue}
                          placeholder={formField.placeholder}
                        />
                      );
                    } else if (formField.element === 'GroupOptions') {
                      return (
                        <GroupOptions
                          key={formField.name}
                          name={formField.name}
                          displayConditions={formField.displayConditions}
                          values={values}
                          text={formField.text}
                          initial={formField.initialValue}
                          options={formField.options}
                          custom={formField.custom}
                          placeholder={formField.placeholder}
                        />
                      );
                    } else if (formField.element === 'TestsuitesOptionsInModal') {
                      return (
                        <TestsuitesOptionsInModal
                          key={formField.name}
                          name={formField.name}
                          values={values}
                          text={formField.text}
                          initial={formField.initialValue}
                          activeModal={activeModal}
                          setActiveModal={setActiveModal}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </Form>
              </Col>
              <Col md={3}>
                {userOptions && userOptions.length > 0 && (
                  <>
                    <h4>Saved options</h4>
                    <ListGroup variant="flush">
                      {map(userOptions, (opt, i) => (
                        <ListGroup.Item key={opt.name + i}>
                          {opt.name}
                          <ButtonGroup className="float-right" size="sm">
                            <Button
                              type="button"
                              variant="outline-success"
                              onClick={() => {
                                if (opt.activeForm) setActiveForm(opt.activeForm);
                                setInitialValues(opt.values);
                                resetForm();
                              }}
                            >
                              Load
                            </Button>
                            <Button
                              type="button"
                              variant="outline-danger"
                              onClick={() => {
                                let newData = filter(userOptions, o => o.name !== opt.name);
                                saveToLocalStorage(newData, USER_DATA);
                                setUserOptions(newData);
                              }}
                            >
                              Delete
                            </Button>
                          </ButtonGroup>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </>
                )}
                <Modal show={activeModal === 'saveUserOptionsName'} onHide={() => setActiveModal()} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Save actual options as...</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Field type="text" className="form-control" name="userOptionName" placeholder="name" />
                    </Form>
                    <Button
                      type="button"
                      variant="outline-primary"
                      className="float-right"
                      onClick={() => {
                        let newData = userOptions || [];
                        newData = [
                          ...newData,
                          {
                            name: values.userOptionName || new Date().toJSON(),
                            activeForm: activeForm,
                            values: values,
                          },
                        ];
                        saveToLocalStorage(newData, USER_DATA);
                        setUserOptions(newData);
                        setActiveModal();
                      }}
                    >
                      Save
                    </Button>
                  </Modal.Body>
                </Modal>
                <Button
                  type="button"
                  variant="outline-primary"
                  className="float-right"
                  onClick={() => setActiveModal('saveUserOptionsName')}
                >
                  Save actual options
                </Button>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
};

export default App;
