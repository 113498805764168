import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field } from 'formik';
import { map } from 'lodash';

import testsuites from './testsuites.json';
import { createCustomName, createOverrideName, evalDisplayConditions } from './utils';

export const SimpleCheckbox = ({ displayConditions, values, name, text, initial }) => {
  if (evalDisplayConditions(values, displayConditions) === true) {
    return (
      <div className="custom-control custom-checkbox">
        <Field type="checkbox" className="custom-control-input" id={name} name={name} />
        <label className="custom-control-label" htmlFor={name}>
          {text} {initial && <span className="text-grey"> (default: {initial})</span>}
        </label>
      </div>
    );
  }

  return null;
};

export const CheckboxWithTextField = ({ values, text, initial, name, placeholder }) => (
  <div className="custom-control custom-checkbox">
    <Field
      type="checkbox"
      className="custom-control-input"
      id={createOverrideName(name)}
      name={createOverrideName(name)}
    />
    <label className="custom-control-label" htmlFor={createOverrideName(name)}>
      {text} {initial && <span className="text-grey"> (default: {initial})</span>}
    </label>
    {values[createOverrideName(name)] && (
      <Field type="text" className="form-control" name={name} placeholder={placeholder} />
    )}
  </div>
);

export const GroupOptions = ({ displayConditions, values, text, initial, options, name, custom, placeholder }) => {
  if (evalDisplayConditions(values, displayConditions) === true) {
    return (
      <div className="custom-control custom-checkbox">
        <Field
          type="checkbox"
          className="custom-control-input"
          id={createOverrideName(name)}
          name={createOverrideName(name)}
        />
        <label className="custom-control-label" htmlFor={createOverrideName(name)}>
          {text} {initial && <span className="text-grey"> (default: {initial})</span>}
        </label>
        <div className="form-inline">
          {values[createOverrideName(name)] && (
            <Field as="select" name={name} className="custom-control custom-select flex-fill">
              {map(options, opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
              {custom && <option value={createCustomName(name)}>custom</option>}
            </Field>
          )}
          {values[createOverrideName(name)] && values[name] === createCustomName(name) && (
            <Field
              type="text"
              className="form-control custom-input flex-fill"
              name={createCustomName(name)}
              placeholder={placeholder}
            />
          )}
        </div>
      </div>
    );
  }

  return null;
};

export const TestsuitesOptionsInModal = ({ values, text, initial, name, activeModal, setActiveModal }) => (
  <>
    <div className="custom-control custom-checkbox">
      <Field
        type="checkbox"
        className="custom-control-input"
        id={createOverrideName(name)}
        name={createOverrideName(name)}
      />
      <label className="custom-control-label" htmlFor={createOverrideName(name)}>
        {text} {initial && <span className="text-grey"> (default: {initial})</span>}
      </label>
      {values[createOverrideName(name)] && (
        <span className="show-modal" onClick={() => setActiveModal(name)}>
          show
        </span>
      )}
    </div>
    <Modal show={activeModal === name} onHide={() => setActiveModal()} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {map(testsuites.tests, test => (
          <div key={test.id} className="custom-control custom-checkbox">
            <Field type="checkbox" className="custom-control-input" id={test.id} name={name} value={test.id} />
            <label className="custom-control-label" htmlFor={test.id}>
              {test.id} <span className="text-grey">{test.name}</span>
            </label>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  </>
);
