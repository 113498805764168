import { join, includes } from 'lodash';
import {
  availableForms,
  allowedLinuxes,
  allowedDBMS,
  allowedJavaVersions,
  allowedRegions,
  allowedInstallMethods,
  proposedTopologies,
  proposedInstanceTypes,
  proposedRepeats,
  proposedNodesCount,
} from './enums';
import { createCustomName, createOverrideName, evalDisplayConditions } from './utils';

export const formFields = {
  commitMessage: {
    name: 'commitMessage',
    element: 'SimpleCheckbox',
    text: 'Use in commit message',
    forms: [availableForms.BAMBOO_TESTER],
  },
  qaBranch: {
    name: 'qaBranch',
    element: 'CheckboxWithTextField',
    text: 'Use custom QA repository branch',
    placeholder: 'branch name (e.g., my-custom-branch)',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER],
  },
  noParallel: {
    name: 'noParallel',
    element: 'SimpleCheckbox',
    text: 'Execute tests in one Test Job (non parallel)',
    forms: [availableForms.BAMBOO_TESTER],
  },
  keepInstances: {
    name: 'keepInstances',
    element: 'SimpleCheckbox',
    text: 'Keep instances running after testing is done',
    forms: [availableForms.BAMBOO_TESTER],
  },
  buildOpts: {
    name: 'buildOpts',
    element: 'h4',
    text: 'Build options',
    forms: [availableForms.BAMBOO_TESTER],
  },
  noRebuild: {
    name: 'noRebuild',
    element: 'SimpleCheckbox',
    text: 'No rebuild new product tarballs',
    forms: [availableForms.BAMBOO_TESTER],
  },
  skipJunit: {
    name: 'skipJunit',
    element: 'SimpleCheckbox',
    text: 'Skip JUnit tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  deployApiDocs: {
    name: 'deployApiDocs',
    element: 'SimpleCheckbox',
    text: 'Deploy API docs',
    forms: [availableForms.BAMBOO_TESTER],
  },
  securityScan: {
    name: 'securityScan',
    element: 'SimpleCheckbox',
    text: 'Run Security scan',
    forms: [availableForms.BAMBOO_TESTER],
  },
  expiry: {
    name: 'expiry',
    element: 'CheckboxWithTextField',
    text: 'Set build expiration time (only for timelocked branches)',
    placeholder: 'expiration in days',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER],
  },
  buildDockerImg: {
    name: 'buildDockerImg',
    element: 'SimpleCheckbox',
    text: 'Build Docker images',
    forms: [availableForms.BAMBOO_TESTER],
  },
  buildAwsAmis: {
    name: 'buildAwsAmis',
    element: 'SimpleCheckbox',
    text: 'Build AWS AMIs',
    forms: [availableForms.BAMBOO_TESTER],
  },
  buildOperator: {
    name: 'buildOperator',
    element: 'SimpleCheckbox',
    text: 'Build Operator',
    forms: [availableForms.BAMBOO_TESTER],
  },
  operatorBranch: {
    name: 'operatorBranch',
    element: 'CheckboxWithTextField',
    text: 'Use custom tungsten-operator repository branch',
    placeholder: 'main',
    initialValue: 'main',
    forms: [availableForms.BAMBOO_TESTER],
  },
  instOpts: {
    name: 'instOpts',
    element: 'h4',
    text: 'Instances options',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  instanceTag: {
    name: 'instanceTag',
    element: 'CheckboxWithTextField',
    text: 'Use custom name for instances identification',
    placeholder: "name (e.g., 'patrik-test' will create instances like 'db1.patrik-test' ...)",
    initialValue: 'prep-cluster',
    forms: [availableForms.PREPARE_CLUSTER],
  },
  instanceCount: {
    name: 'instanceCount',
    element: 'CheckboxWithTextField',
    text: 'Force to prepare exact count of instances',
    placeholder: 'count of instances',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER],
  },
  instanceStartIdx: {
    name: 'instanceStartIdx',
    element: 'CheckboxWithTextField',
    text: 'Start indexing instances from custom number',
    placeholder: '1',
    initialValue: '1',
    forms: [availableForms.PREPARE_CLUSTER],
  },
  instanceType: {
    name: 'instanceType',
    element: 'GroupOptions',
    text: 'Use custom instance type',
    options: proposedInstanceTypes,
    initialValue: 'm5.large',
    custom: true,
    placeholder: 'm5.large',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  region: {
    name: 'region',
    element: 'GroupOptions',
    text: 'Run instances in selected region',
    options: allowedRegions,
    initialValue: 'use',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  secondNic: {
    name: 'secondNic',
    element: 'SimpleCheckbox',
    text: 'Prepare second NIC on instances',
    forms: [availableForms.PREPARE_CLUSTER],
  },
  ipv6dns: {
    name: 'ipv6dns',
    element: 'SimpleCheckbox',
    displayConditions: [
      { values: 'linuxType', equals: 'al2-full' },
      { values: createOverrideName('linuxType'), equals: false },
    ],
    text: 'Prepare instances with IPv6',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  linuxType: {
    name: 'linuxType',
    element: 'GroupOptions',
    text: 'Use custom Linux distribution',
    options: allowedLinuxes,
    initialValue: 'al2-full',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  dbType: {
    name: 'dbType',
    element: 'GroupOptions',
    displayConditions: [
      { values: 'linuxType', equals: 'al2-full' },
      { values: createOverrideName('linuxType'), equals: false },
    ],
    text: 'Use custom DBMS',
    options: allowedDBMS,
    initialValue: 'mysql-8.0',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  javaVersion: {
    name: 'javaVersion',
    element: 'GroupOptions',
    displayConditions: [
      { values: 'linuxType', equals: 'al2-full' },
      { values: createOverrideName('linuxType'), equals: false },
    ],
    text: 'Use custom Java version',
    options: allowedJavaVersions,
    initialValue: '11',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  moreDbOpts: {
    name: 'moreDbOpts',
    element: 'CheckboxWithTextField',
    text: 'More database options',
    placeholder: 'space-separated database config (e.g., binlog_format=row)',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  instancesIPs: {
    name: 'instancesIPs',
    element: 'CheckboxWithTextField',
    text: 'Use instances IPs',
    placeholder: 'space-separated IPs (e.g., 10.10.0.10 10.10.0.11)',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER, availableForms.TEST_CLUSTER],
  },
  installOpts: {
    name: 'installOpts',
    element: 'h4',
    text: 'Installation options',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  skipInstall: {
    name: 'skipInstall',
    element: 'SimpleCheckbox',
    text: 'Skip installation',
    forms: [availableForms.PREPARE_CLUSTER],
  },
  nodesCount: {
    name: 'nodesCount',
    element: 'GroupOptions',
    displayConditions: [{ values: 'skipInstall', equals: true }],
    text: 'Number of nodes',
    options: proposedNodesCount,
    initialValue: '3',
    custom: true,
    placeholder: '3',
    forms: [availableForms.PREPARE_CLUSTER],
  },
  installMethod: {
    name: 'installMethod',
    element: 'GroupOptions',
    text: 'Installation method',
    options: allowedInstallMethods,
    initialValue: 'ini',
    forms: [availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  topology: {
    name: 'topology',
    element: 'GroupOptions',
    text: 'Topology',
    options: proposedTopologies,
    initialValue: 'std-3',
    custom: true,
    placeholder: '[ topology ]-[ clusters count ]x[ nodes per cluster ]',
    forms: [availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  enableSsl: {
    name: 'enableSsl',
    element: 'SimpleCheckbox',
    text: 'Enable SSL',
    forms: [availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  enableApi: {
    name: 'enableApi',
    element: 'SimpleCheckbox',
    text: 'Enable APIv2',
    forms: [availableForms.PREPARE_CLUSTER],
  },
  moreInstallOpts: {
    name: 'moreInstallOpts',
    element: 'CheckboxWithTextField',
    text: 'More tpm installation options',
    placeholder: 'space-separated tpm options (e.g., --connector-bridge-mode=false)',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  tarVersion: {
    name: 'tarVersion',
    element: 'CheckboxWithTextField',
    text: 'Tarball version',
    placeholder: '7.0.0',
    initialValue: 'latest',
    forms: [availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  tarBranch: {
    name: 'tarBranch',
    element: 'CheckboxWithTextField',
    text: 'Tarball branch',
    placeholder: 'cnt-x.y.z-merge',
    initialValue: 'main',
    forms: [availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  tarBuildNum: {
    name: 'tarBuildNum',
    element: 'CheckboxWithTextField',
    text: 'Tarball build number',
    placeholder: '20',
    initialValue: 'latest',
    forms: [availableForms.BAMBOO_TESTER, availableForms.PREPARE_CLUSTER, availableForms.TEST_CLUSTER],
  },
  testOpts: {
    name: 'testOpts',
    element: 'h4',
    text: 'Tests options',
    forms: [availableForms.BAMBOO_TESTER, availableForms.TEST_CLUSTER],
  },
  runMATS: {
    name: 'runMATS',
    element: 'SimpleCheckbox',
    text: 'Run MATS tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runCT: {
    name: 'runCT',
    element: 'SimpleCheckbox',
    text: 'Run CT tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runTR: {
    name: 'runTR',
    element: 'SimpleCheckbox',
    text: 'Run TR tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runTPM: {
    name: 'runTPM',
    element: 'SimpleCheckbox',
    text: 'Run TPM tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runAPI: {
    name: 'runAPI',
    element: 'SimpleCheckbox',
    text: 'Run API tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runK8: {
    name: 'runK8',
    element: 'SimpleCheckbox',
    text: 'Run Tungsten Operator tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  operatorTag: {
    name: 'operatorTag',
    element: 'CheckboxWithTextField',
    text: 'Operator tag to test',
    placeholder: '8.0.0-1',
    initialValue: 'latest',
    forms: [availableForms.BAMBOO_TESTER],
  },
  testMULTIREGION: {
    name: 'testMULTIREGION',
    element: 'SimpleCheckbox',
    text: 'Run tests in multiple regions',
    forms: [availableForms.BAMBOO_TESTER],
  },
  testMULTILINUX: {
    name: 'testMULTILINUX',
    element: 'SimpleCheckbox',
    text: 'Run tests on multiple Linux distributions',
    forms: [availableForms.BAMBOO_TESTER],
  },
  testMULTIDBMS: {
    name: 'testMULTIDBMS',
    element: 'SimpleCheckbox',
    text: 'Run tests on multiple DBMS',
    forms: [availableForms.BAMBOO_TESTER],
  },
  testMULTIJAVA: {
    name: 'testMULTIJAVA',
    element: 'SimpleCheckbox',
    text: 'Run tests on multiple Java versions',
    forms: [availableForms.BAMBOO_TESTER],
  },
  testALL: {
    name: 'testALL',
    element: 'SimpleCheckbox',
    text: 'Run all tests (CT, TR, TPM)',
    forms: [availableForms.BAMBOO_TESTER],
  },
  release: {
    name: 'release',
    element: 'SimpleCheckbox',
    text: 'Run release',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runOnly: {
    name: 'runOnly',
    element: 'TestsuitesOptionsInModal',
    text: 'Run only specific tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  ignore: {
    name: 'ignore',
    element: 'TestsuitesOptionsInModal',
    text: 'Ignore specific tests',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runUserTests: {
    name: 'runUserTests',
    element: 'CheckboxWithTextField',
    text: 'Run single user defined test scenario (json/perl)',
    placeholder: 'std-3_stag_noload/ct-90.json',
    initialValue: '',
    forms: [availableForms.BAMBOO_TESTER],
  },
  runTests: {
    name: 'runTests',
    element: 'CheckboxWithTextField',
    text: 'Run tests (allowed cluster operations: switch failover recover backup restore contents cswitch cfailover crecover)',
    placeholder: 'switch failover recover backup restore contents cswitch cfailover crecover',
    forms: [availableForms.TEST_CLUSTER],
  },
  runUnderload: {
    name: 'runUnderload',
    element: 'SimpleCheckbox',
    text: 'Run tests under load',
    forms: [availableForms.TEST_CLUSTER],
  },
  debug: {
    name: 'debug',
    element: 'SimpleCheckbox',
    text: 'Run in debug mode (stop on failure)',
    forms: [availableForms.BAMBOO_TESTER, availableForms.TEST_CLUSTER],
  },
  repeat: {
    name: 'repeat',
    element: 'GroupOptions',
    text: 'Repeat selected tests',
    options: proposedRepeats,
    initialValue: '1',
    custom: true,
    placeholder: '2',
    forms: [availableForms.BAMBOO_TESTER],
  },
};

export const createOptions = (values, activeForm) => {
  let optionsText = '';

  // BAMBOO
  if (includes(formFields.commitMessage.forms, activeForm)) {
    if (values[formFields.commitMessage.name]) {
      optionsText += '[ BAMBOO';
    }
  }

  // qa-branch
  if (includes(formFields.qaBranch.forms, activeForm)) {
    if (values[createOverrideName(formFields.qaBranch.name)] && values[formFields.qaBranch.name]) {
      optionsText += ' qa-branch=' + values[formFields.qaBranch.name];
    }
  }

  // NO-PARALLEL
  if (includes(formFields.noParallel.forms, activeForm)) {
    if (values[formFields.noParallel.name]) {
      optionsText += ' NO-PARALLEL';
    }
  }

  // KEEP-INSTANCES
  if (includes(formFields.keepInstances.forms, activeForm)) {
    if (values[formFields.keepInstances.name]) {
      optionsText += ' KEEP-INSTANCES';
    }
  }

  // NO-REBUILD
  if (includes(formFields.noRebuild.forms, activeForm)) {
    if (values[formFields.noRebuild.name]) {
      optionsText += ' NO-REBUILD';
    }
  }

  // SKIP-JUNIT
  if (includes(formFields.skipJunit.forms, activeForm)) {
    if (values[formFields.skipJunit.name]) {
      optionsText += ' SKIP-JUNIT';
    }
  }

  // DEPLOY-API-DOCS
  if (includes(formFields.deployApiDocs.forms, activeForm)) {
    if (values[formFields.deployApiDocs.name]) {
      optionsText += ' DEPLOY-API-DOCS';
    }
  }

  // SECURITY-SCAN
  if (includes(formFields.securityScan.forms, activeForm)) {
    if (values[formFields.securityScan.name]) {
      optionsText += ' SECURITY-SCAN';
    }
  }

  // expiry
  if (includes(formFields.expiry.forms, activeForm)) {
    if (values[createOverrideName(formFields.expiry.name)] && values[formFields.expiry.name]) {
      optionsText += ' expiry=' + values[formFields.expiry.name];
    }
  }

  // BUILD-DOCKER-IMAGES
  if (includes(formFields.buildDockerImg.forms, activeForm)) {
    if (values[formFields.buildDockerImg.name]) {
      optionsText += ' BUILD-DOCKER-IMAGES';
    }
  }

  // BUILD-AWS-AMIS
  if (includes(formFields.buildAwsAmis.forms, activeForm)) {
    if (values[formFields.buildAwsAmis.name]) {
      optionsText += ' BUILD-AWS-AMIS';
    }
  }

  // BUILD-OPERATOR
  if (includes(formFields.buildOperator.forms, activeForm)) {
    if (values[formFields.buildOperator.name]) {
      optionsText += ' BUILD-OPERATOR';
    }
  }

  // operator-branch
  if (includes(formFields.operatorBranch.forms, activeForm)) {
    if (values[createOverrideName(formFields.operatorBranch.name)] && values[formFields.operatorBranch.name]) {
      optionsText += ' operator-branch=' + values[formFields.operatorBranch.name];
    }
  }

  // instance-tag
  if (includes(formFields.instanceTag.forms, activeForm)) {
    if (values[createOverrideName(formFields.instanceTag.name)] && values[formFields.instanceTag.name]) {
      optionsText += ' instance-tag=' + values[formFields.instanceTag.name];
    }
  }

  // instance-count
  if (includes(formFields.instanceCount.forms, activeForm)) {
    if (values[createOverrideName(formFields.instanceCount.name)] && values[formFields.instanceCount.name]) {
      optionsText += ' instance-count=' + values[formFields.instanceCount.name];
    }
  }

  // instance-start-from
  if (includes(formFields.instanceStartIdx.forms, activeForm)) {
    if (values[createOverrideName(formFields.instanceStartIdx.name)] && values[formFields.instanceStartIdx.name]) {
      optionsText += ' instance-start-from=' + values[formFields.instanceStartIdx.name];
    }
  }

  // instance-type
  if (includes(formFields.instanceType.forms, activeForm)) {
    if (values[createOverrideName(formFields.instanceType.name)] && values[formFields.instanceType.name]) {
      if (values[formFields.instanceType.name] === createCustomName(formFields.instanceType.name)) {
        if (values[createCustomName(formFields.instanceType.name)]) {
          optionsText += ' instance-type=' + values[createCustomName(formFields.instanceType.name)];
        }
      } else {
        optionsText += ' instance-type=' + values[formFields.instanceType.name];
      }
    }
  }

  // region
  if (includes(formFields.region.forms, activeForm)) {
    if (values[createOverrideName(formFields.region.name)] && values[formFields.region.name]) {
      optionsText += ' region=' + values[formFields.region.name];
    }
  }

  // SECOND-NIC
  if (includes(formFields.secondNic.forms, activeForm)) {
    if (values[formFields.secondNic.name]) {
      optionsText += ' SECOND-NIC';
    }
  }

  // IPv6
  if (includes(formFields.ipv6dns.forms, activeForm)) {
    if (values[formFields.ipv6dns.name]) {
      if (evalDisplayConditions(values, formFields.ipv6dns.displayConditions) === true) {
        optionsText += ' IPv6';
      }
    }
  }

  // linux
  if (includes(formFields.linuxType.forms, activeForm)) {
    if (values[createOverrideName(formFields.linuxType.name)] && values[formFields.linuxType.name]) {
      optionsText += ' linux=' + values[formFields.linuxType.name];
    }
  }

  // mysql
  if (includes(formFields.dbType.forms, activeForm)) {
    if (values[createOverrideName(formFields.dbType.name)] && values[formFields.dbType.name]) {
      if (evalDisplayConditions(values, formFields.dbType.displayConditions) === true) {
        optionsText += ' dbms=' + values[formFields.dbType.name];
      }
    }
  }

  // java
  if (includes(formFields.javaVersion.forms, activeForm)) {
    if (values[createOverrideName(formFields.javaVersion.name)] && values[formFields.javaVersion.name]) {
      if (evalDisplayConditions(values, formFields.javaVersion.displayConditions) === true) {
        optionsText += ' java=' + values[formFields.javaVersion.name];
      }
    }
  }

  // more-db-opts
  if (includes(formFields.moreDbOpts.forms, activeForm)) {
    if (values[createOverrideName(formFields.moreDbOpts.name)] && values[formFields.moreDbOpts.name]) {
      optionsText += ' more-db-opts=(' + values[formFields.moreDbOpts.name] + ')';
    }
  }

  // instances
  if (includes(formFields.instancesIPs.forms, activeForm)) {
    if (values[createOverrideName(formFields.instancesIPs.name)] && values[formFields.instancesIPs.name]) {
      optionsText += ' instances=(' + values[formFields.instancesIPs.name] + ')';
    }
  }

  // SKIP-INSTALL
  if (includes(formFields.skipInstall.forms, activeForm)) {
    if (values[formFields.skipInstall.name]) {
      optionsText += ' SKIP-INSTALL';
    }
  }

  // number of nodes when skip install (std topology)
  if (includes(formFields.nodesCount.forms, activeForm) && values[formFields.skipInstall.name]) {
    if (values[createOverrideName(formFields.nodesCount.name)] && values[formFields.nodesCount.name]) {
      if (values[formFields.nodesCount.name] === createCustomName(formFields.nodesCount.name)) {
        if (values[createCustomName(formFields.nodesCount.name)]) {
          optionsText += ' topology=std-' + values[createCustomName(formFields.nodesCount.name)];
        }
      } else {
        optionsText += ' topology=std-' + values[formFields.nodesCount.name];
      }
    }
  }

  // install-method
  if (includes(formFields.installMethod.forms, activeForm)) {
    if (values[createOverrideName(formFields.installMethod.name)] && values[formFields.installMethod.name]) {
      optionsText += ' install-method=' + values[formFields.installMethod.name];
    }
  }

  // topology
  if (includes(formFields.topology.forms, activeForm)) {
    if (values[createOverrideName(formFields.topology.name)] && values[formFields.topology.name]) {
      if (values[formFields.topology.name] === createCustomName(formFields.topology.name)) {
        if (values[createCustomName(formFields.topology.name)]) {
          optionsText += ' topology=' + values[createCustomName(formFields.topology.name)];
        }
      } else {
        optionsText += ' topology=' + values[formFields.topology.name];
      }
    }
  }

  // ENABLE-SSL
  if (includes(formFields.enableSsl.forms, activeForm)) {
    if (values[formFields.enableSsl.name]) {
      optionsText += ' ENABLE-SSL';
    }
  }

  // ENABLE-API
  if (includes(formFields.enableApi.forms, activeForm)) {
    if (values[formFields.enableApi.name]) {
      optionsText += ' ENABLE-API';
    }
  }

  // more-install-opts
  if (includes(formFields.moreInstallOpts.forms, activeForm)) {
    if (values[createOverrideName(formFields.moreInstallOpts.name)] && values[formFields.moreInstallOpts.name]) {
      optionsText += ' more-install-opts=(' + values[formFields.moreInstallOpts.name] + ')';
    }
  }

  // tar-version
  if (includes(formFields.tarVersion.forms, activeForm)) {
    if (values[createOverrideName(formFields.tarVersion.name)] && values[formFields.tarVersion.name]) {
      if (values[formFields.tarVersion.name] === createCustomName(formFields.tarVersion.name)) {
        if (values[createCustomName(formFields.tarVersion.name)]) {
          optionsText += ' tar-version=' + values[createCustomName(formFields.tarVersion.name)];
        }
      } else {
        optionsText += ' tar-version=' + values[formFields.tarVersion.name];
      }
    }
  }

  // tar-branch
  if (includes(formFields.tarBranch.forms, activeForm)) {
    if (values[createOverrideName(formFields.tarBranch.name)] && values[formFields.tarBranch.name]) {
      if (values[formFields.tarBranch.name] === createCustomName(formFields.tarBranch.name)) {
        if (values[createCustomName(formFields.tarBranch.name)]) {
          optionsText += ' tar-branch=' + values[createCustomName(formFields.tarBranch.name)];
        }
      } else {
        optionsText += ' tar-branch=' + values[formFields.tarBranch.name];
      }
    }
  }

  // tar-build
  if (includes(formFields.tarBuildNum.forms, activeForm)) {
    if (values[createOverrideName(formFields.tarBuildNum.name)] && values[formFields.tarBuildNum.name]) {
      optionsText += ' tar-build=' + values[formFields.tarBuildNum.name];
    }
  }

  // TEST-MATS
  if (includes(formFields.runMATS.forms, activeForm)) {
    if (values[formFields.runMATS.name]) {
      optionsText += ' TEST-MATS';
    }
  }

  // TEST-CT
  if (includes(formFields.runCT.forms, activeForm)) {
    if (values[formFields.runCT.name]) {
      optionsText += ' TEST-CT';
    }
  }

  // TEST-TR
  if (includes(formFields.runTR.forms, activeForm)) {
    if (values[formFields.runTR.name]) {
      optionsText += ' TEST-TR';
    }
  }

  // TEST-TPM
  if (includes(formFields.runTPM.forms, activeForm)) {
    if (values[formFields.runTPM.name]) {
      optionsText += ' TEST-TPM';
    }
  }

  // TEST-API
  if (includes(formFields.runAPI.forms, activeForm)) {
    if (values[formFields.runAPI.name]) {
      optionsText += ' TEST-API';
    }
  }

  // TEST-K8
  if (includes(formFields.runK8.forms, activeForm)) {
    if (values[formFields.runK8.name]) {
      optionsText += ' TEST-K8';
    }
  }

  // operator-tag
  if (includes(formFields.operatorTag.forms, activeForm)) {
    if (values[createOverrideName(formFields.operatorTag.name)] && values[formFields.operatorTag.name]) {
      optionsText += ' operator-tag=' + values[formFields.operatorTag.name];
    }
  }

  // MULTIREGION
  if (includes(formFields.testMULTIREGION.forms, activeForm)) {
    if (values[formFields.testMULTIREGION.name]) {
      optionsText += ' MULTIREGION';
    }
  }

  // MULTILINUX
  if (includes(formFields.testMULTILINUX.forms, activeForm)) {
    if (values[formFields.testMULTILINUX.name]) {
      optionsText += ' MULTILINUX';
    }
  }

  // MULTIDBMS
  if (includes(formFields.testMULTIDBMS.forms, activeForm)) {
    if (values[formFields.testMULTIDBMS.name]) {
      optionsText += ' MULTIDBMS';
    }
  }

  // MULTIJAVA
  if (includes(formFields.testMULTIJAVA.forms, activeForm)) {
    if (values[formFields.testMULTIJAVA.name]) {
      optionsText += ' MULTIJAVA';
    }
  }

  // TEST-ALL
  if (includes(formFields.testALL.forms, activeForm)) {
    if (values[formFields.testALL.name]) {
      optionsText += ' TEST-ALL';
    }
  }

  // RELEASE
  if (includes(formFields.release.forms, activeForm)) {
    if (values[formFields.release.name]) {
      optionsText += ' RELEASE';
    }
  }

  // run-only
  if (includes(formFields.runOnly.forms, activeForm)) {
    if (values[createOverrideName(formFields.runOnly.name)] && values[formFields.runOnly.name]) {
      optionsText += ' run-only=(';
      optionsText += join(values[formFields.runOnly.name], ' ');
      optionsText += ')';
    }
  }

  // ignore
  if (includes(formFields.ignore.forms, activeForm)) {
    if (values[createOverrideName(formFields.ignore.name)] && values[formFields.ignore.name]) {
      optionsText += ' ignore=(';
      optionsText += join(values[formFields.ignore.name], ' ');
      optionsText += ')';
    }
  }

  // user-tests
  if (includes(formFields.runUserTests.forms, activeForm)) {
    if (values[createOverrideName(formFields.runUserTests.name)] && values[formFields.runUserTests.name]) {
      optionsText += ' user-tests=(' + values[formFields.runUserTests.name] + ')';
    }
  }

  // tests (test cluster)
  if (includes(formFields.runTests.forms, activeForm)) {
    if (values[createOverrideName(formFields.runTests.name)] && values[formFields.runTests.name]) {
      optionsText += ' tests=(' + values[formFields.runTests.name] + ')';
    }
  }

  // UNDERLOAD
  if (includes(formFields.runUnderload.forms, activeForm)) {
    if (values[formFields.runUnderload.name]) {
      optionsText += ' UNDERLOAD';
    }
  }

  // DEBUG
  if (includes(formFields.debug.forms, activeForm)) {
    if (values[formFields.debug.name]) {
      optionsText += ' DEBUG';
    }
  }

  // repeat
  if (includes(formFields.repeat.forms, activeForm)) {
    if (values[createOverrideName(formFields.repeat.name)] && values[formFields.repeat.name]) {
      if (values[formFields.repeat.name] === createCustomName(formFields.repeat.name)) {
        if (values[createCustomName(formFields.repeat.name)]) {
          optionsText += ' repeat=' + values[createCustomName(formFields.repeat.name)];
        }
      } else {
        optionsText += ' repeat=' + values[formFields.repeat.name];
      }
    }
  }

  // BAMBOO end bracket
  if (includes(formFields.commitMessage.forms, activeForm)) {
    if (values[formFields.commitMessage.name]) {
      optionsText += ' ]';
    }
  }

  return optionsText;
};
