import { map } from 'lodash';

import { ACTUAL_DATA } from './enums';

export const openLink = link => window.open(link, '_blank');

export const saveToLocalStorage = (data, key) => {
  if (!key) {
    key = ACTUAL_DATA;
  }

  window.localStorage.setItem(key, JSON.stringify(data));
};

export const readFromLocalStorage = key => {
  if (!key) {
    key = ACTUAL_DATA;
  }

  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const createOverrideName = name => `override${name}`;

export const createCustomName = name => `custom${name}`;

export const evalDisplayConditions = (values, conditions) => {
  let displayCondition = false;

  if (conditions) {
    map(conditions, condition => {
      if (values[condition.values] === condition.equals) {
        displayCondition = true;
      }
    });
  } else {
    displayCondition = true;
  }

  return displayCondition;
};
